import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login'
import { getDatos } from './providers/dataServer';
import Dashboard from './pages/Dashboard';
import Imagenes from './pages/Imagenes';
import Cobertura from './pages/Cobertura';
import Usuarios from './pages/Usuarios';
import Pagos from './pages/Pagos';
import Distribuidores from './pages/Distribuidores';
import { useEffect, useState } from 'react';
import CoberturaHijos from './pages/CoberturaHijos';
import ContenApp from './pages/ContentApp';

function App() {

  const [logeado, setlogeado] = useState(false)

  useEffect(() => {
    const getLogin = async () => {
      const response = await getDatos('/admin/auth/valid-token')
      if (response.ok) {
        setlogeado(true)
      }
    }
    getLogin()
  }, [])

  return (
    <Routes>
      <Route path="/" element={logeado ? <ContenApp logout={() => setlogeado(false)} /> : <Navigate to={'/login'} />} >
        <Route index element={<Dashboard />} />
        <Route path='imagenes' element={<Imagenes />} />
        <Route path='cobertura'>
          <Route index element={<Cobertura />} />
          <Route path=':idCobertura/:nombreCiudad/:cordenadasCiudad' element={<CoberturaHijos />} />
        </Route>
        <Route path='usuarios' element={<Usuarios />} />
        <Route path='pagos' element={<Pagos />} />
        <Route path='distribuidores' element={<Distribuidores />} />
      </Route>
      <Route path='login' element={!logeado ? <Login setlogeado={() => setlogeado(true)} /> : <Navigate to={'/'} />} />
    </Routes>
  );
}

export default App;
