import Spinner from "../components/Spinner"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from "../providers/dataServer"
import { useEffect, useRef, useState } from "react"
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import Icons from "../components/Icons"
import { useNavigate, useParams } from "react-router-dom"
import MapView from "../components/Map"
import MapaSectores from "../components/MapaSectores"

function ModalModificar({ elemento, cerrar, idPadre, cordenadasPadre }) {

    const queryClient = useQueryClient()
    const [polygonCoords, setPolygonCoords] = useState([]);
    const [mostrarClick, setMostrarClick] = useState(false);

    const { data: padres = [], isLoadingPadres } = useQuery(["configs", "cobertura"], () => getDatos('/admin/getCobertura'), {
        refetchOnMount: true,
    })

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            ...elemento,
            poligono: [],
            radio_cobertura: 1,
            idPadre: idPadre
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/admin/setCobertura', parametros: datos })
    })
    useEffect(() => {
        if (elemento.poligono) {
            setPolygonCoords(JSON.parse(elemento.poligono))
            setValue('poligono', JSON.parse(elemento.poligono))
        }
        if (!elemento.id) {
            setMostrarClick(true)
        }
    }, [elemento])

    function setCenter(cordenadas) {
        try {
            setValue('latitud', cordenadas.lat())
            setValue('longitud', cordenadas.lng())
        } catch (error) {
            setValue('latitud', cordenadas.lat)
            setValue('longitud', cordenadas.lng)
        }
    }

    function setNewPolygon(poligono) {
        setValue('poligono', poligono)
        setMostrarClick(false)
    }
    function setRadio(radio) {
        setValue('radio_cobertura', radio / 1000)
    }


    const onSubmit = handleSubmit(datos => {
        let newDatos = {}
        newDatos = { ...datos }
        newDatos.poligono = JSON.stringify(newDatos.poligono)
        mutate(newDatos, {
            onSuccess: (data) => {
                if (data.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Se actualizó la información'
                    }).then(() => {
                        cerrar();
                        queryClient.removeQueries({ queryKey: ["configs", "cobertura"] })
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.error ?? `No se pudo completar la operación`
                    })
                }
            }
        })
    })

    return <div className="modal">
        {(isLoading || isLoadingPadres) && <Spinner />}
        <div className="modal-modificar" style={{ width: 600 }}>
            <div className="s-modal-header">
                <h2>{!!elemento.id ? 'Modificar' : 'Agregar'} {elemento.nombre}</h2>
                <button className="btn-cerrar-modal2" onClick={cerrar}>Cerrar</button>
            </div>
            <form onSubmit={onSubmit}>
                <div className="cont-inpt">
                    <label htmlFor="nombre">Nombre Ciudad:</label>
                    <input type="text" {...register('nombre', {
                        required: {
                            value: true,
                            message: 'El nombre es requerido'
                        }
                    })} />
                    <span>{!!errors['nombre'] && errors['nombre'].message}</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="idPadre">Sector Padre:</label>
                    <select {...register('idPadre', {
                        required: {
                            value: true,
                            message: 'Elige un sector'
                        }
                    })}>
                        {padres?.map((padre, index) => <option key={index} value={padre.id}>{padre.nombre}</option>)}
                    </select>
                    <span>{!!errors['tecnico'] && errors['tecnico'].message}</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="tecnico">Técnico Asignado:</label>
                    <input type="tecnico" {...register('tecnico', {
                        required: {
                            value: true,
                            message: 'El tecnico es requerido'
                        }
                    })} />
                    <span>{!!errors['tecnico'] && errors['tecnico'].message}</span>
                </div>
                <p className="titulo-sectores-mapa">Sectores de cobertura</p>
                <MapView initialLocation={{ lat: parseFloat(elemento.latitud), lng: parseFloat(elemento.longitud) }} polygonCoords={polygonCoords} setCenter={setCenter} setNewPolygon={setNewPolygon} setRadio={setRadio} cordenadasPadre={{ lat: parseFloat(cordenadasPadre.lat), lng: parseFloat(cordenadasPadre.lng) }}/>
                {mostrarClick && <p>Elige el lugar y da click para generar el polígono de cobertura</p>}
                {(watch('poligono').length > 2) && <button type="submit">Guardar</button>}
            </form>
        </div>
    </div>
}

export default function CoberturaHijos() {

    const { idCobertura, nombreCiudad, cordenadasCiudad } = useParams();
    const mapaSectoresRef = useRef(null);
    const [elemento, setElemento] = useState({})
    const [resaltar, setResaltar] = useState(0)
    const [ centroMapa, setCentroMapa ] = useState(JSON.parse(cordenadasCiudad))
    const [mostrarModal, setmostarmodal] = useState(false)
    const [sectores, setSectores] = useState([])
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { data: configs = [], isLoading } = useQuery(["configs", "cobertura", idCobertura], () => getDatos('/admin/getCobertura', { id: idCobertura }), {
        refetchOnMount: true,
        onSuccess: (datos) => {
            const sectores = procesarDatos(datos);
            setSectores(sectores);
        }
    })
    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/admin/delCobertura', parametros: datos })
    })

    function editar(elemento) {
        setElemento(elemento)
        setmostarmodal(true)
    }

    const procesarDatos = (datos) => {
        return datos.map(item => {
            const poligono = JSON.parse(item.poligono);
            return poligono.map(punto => ({
                lat: punto.lat,
                lng: punto.lng,
                color: item.color,
                nombre: item.nombre,
                idZona: item.id,
                centro: { lat: parseFloat(item.latitud), lng: parseFloat(item.longitud) }
            }));
        });
    };

    function resaltarFila(id, coordenadasCentro) {
        try {
            setCentroMapa({ lat: coordenadasCentro.lat(), lng: coordenadasCentro.lng() })
        } catch (error) {
            setCentroMapa({ lat: coordenadasCentro.lat, lng: coordenadasCentro.lng })
        }
        setResaltar(id)
    }

    const handleSectorClick = (idZona) => {
        if (mapaSectoresRef.current) {
            mapaSectoresRef.current.triggerPolygonClick(idZona);
        }
    };
    
    function eliminar(id) {
        Swal.fire({
            icon: 'question',
            title: 'Eliminar Sector?',
            text: 'Se eliminará este Sub-sector',
            confirmButtonText: `Si, Eliminar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if (result.isConfirmed) {
                mutate({ id: id }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.removeQueries({ queryKey: ["configs", "cobertura"] })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: data.error ?? `No se pudo completar la operación`
                            })
                        }
                    }
                })
            }
        })
    }
    return <div className="tablas">
        {(isLoading || isMutating) && <Spinner />}
        {mostrarModal && <ModalModificar elemento={elemento} cerrar={() => setmostarmodal(false)} idPadre={idCobertura} cordenadasPadre={JSON.parse(cordenadasCiudad)} />}
        <div className="row">
            <div className="cont-tabla-imagenes">
                <div className="header-section">
                    <div className="cont-header-arrow">
                        <div className="cont-header-arrow-icon" onClick={() => navigate(-1)} >
                            <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                        </div>
                        <h2>Sectores de {nombreCiudad}</h2>
                    </div>
                    <button className="agregar" onClick={() => editar({})} >Agregar SubSector</button>
                </div>
                <div className="cont-mapa-hijos">
                    <MapaSectores ref={mapaSectoresRef} sectores={sectores} cordenadasPadre={centroMapa} setResaltado={(id, coordenadasCentro) => resaltarFila(id, coordenadasCentro)} onCloseInfoWindow={() => setResaltar(0)}/>
                </div>
                <table style={{ marginTop: 0 }}>
                    <thead>
                        <tr>
                            <th style={{ width: 10 }}>#ID</th>
                            <th>Ciudad</th>
                            <th>Latitud</th>
                            <th>Longitud</th>
                            <th>Técnico</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            configs.map((conf, i) => {
                                return <tr key={i} style={ resaltar === conf.id ? { backgroundColor: '#00ff9e' } : {}} >
                                    <td style={{ backgroundColor: conf.color, cursor: 'pointer' }} onClick={() => handleSectorClick(conf.id)}>{conf.id}</td>
                                    <td>{conf.nombre}</td>
                                    <td>{conf.latitud}</td>
                                    <td>{conf.longitud}</td>
                                    <td>{conf.tecnico}</td>
                                    <td>
                                        <button onClick={() => editar(conf)} title="Editar">
                                            <Icons req={'edit'} height={'1.5em'} fill={'white'}/>
                                        </button>
                                        <button style={{ backgroundColor: 'red' }} onClick={() => { eliminar(conf.id) }} title="Eliminar">
                                            <Icons req={'delete'} height={'1.5em'} fill={'white'} />
                                        </button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}