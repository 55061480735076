import Spinner from "../components/Spinner"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from "../providers/dataServer"
import { useState } from "react"
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"
import Icons from "../components/Icons"

function ModalModificar({elemento, cerrar}) {

    const queryClient = useQueryClient()

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            ...elemento
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setImagen', parametros: datos})
    })

    const onSubmit = handleSubmit( datos => mutate(datos, {
        onSuccess: (data) => {
            if (data.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizado',
                    text: 'Se actualizó la información'
                }).then(() => {
                    cerrar();
                    queryClient.removeQueries({ queryKey: ["configs", "imagenes"] })
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.error ?? `No se pudo completar la operación`
                })
            }
        }
    }))

    function esUrlDeImagen(url) {
        const img = new Image();
        img.src = url;
      
        return new Promise((resolve) => {
          img.onload = () => {
            resolve(true);
          };
      
          img.onerror = () => {
            resolve(false);
          };
        });
    }

    function esUrlValida(url) {
        try {
          new URL(url);
          return true;
        } catch (error) {
          return false;
        }
    }

    return <div className="modal">
        { isLoading && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                <h2>Modificar { elemento.nombre }</h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <form onSubmit={ onSubmit }>
                <div className="cont-inpt">
                    <label htmlFor="url">Url:</label>
                    <input type="text" { ...register('url', {
                                required: {
                                    value: true,
                                    message: 'La url no es válida'
                                },
                                validate: async v => await esUrlDeImagen(v) || 'No es una imagen valida'
                            }) } />
                    <span>{ !!errors['url'] && errors['url'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="url_accion">Url Acción:</label>
                    <input type="text" { ...register('url_accion', {
                                required: false,
                                validate: v => esUrlValida || 'La url no es válida'
                            }) } />
                    <span>{ !!errors['url_accion'] && errors['url_accion'].message }</span>
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    </div>
}

export default function Imagenes() {

    const [ elemento, setElemento ] = useState({})
    const [ mostrarModal, setmostarmodal ] = useState(false)
    const navigate = useNavigate()

    const { data: configs = [], isLoading } = useQuery(["configs", "imagenes"], () => getDatos('/admin/getImagenes'))

    function editar(elemento) {
        setElemento(elemento)
        setmostarmodal(true)
    }

    return <div className="tablas">
        { isLoading && <Spinner/> }
        { mostrarModal && <ModalModificar elemento={ elemento } cerrar={ () => setmostarmodal(false) } /> }
        <div className="row">
            <div className="cont-tabla-imagenes">
                <div className="header-section">
                    <div className="cont-header-arrow">
                        <div className="cont-header-arrow-icon" onClick={ () => navigate(-1) } >
                            <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                        </div>
                        <h2>Imágenes HOME APP MOVIL</h2>
                    </div>
                    {/**<button className="agregar" onClick={ ()=>editar({}) } >Agregar</button>**/}
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>URL</th>
                            <th>URL Acción</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        configs.map((conf, i)=>{
                            return <tr key={i}>
                                <td>{ conf.nombre }</td>
                                <td>{ conf.url }</td>
                                <td>{ conf.url_accion }</td>
                                <td>
                                    <button onClick={()=>{ editar(conf) }}>Editar</button>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}