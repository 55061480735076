import Spinner from "../components/Spinner"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from "../providers/dataServer"
import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import Icons from "../components/Icons"
import { Link, useNavigate } from "react-router-dom"
import Pagination from "../components/Pagination"
import JsonView from "react18-json-view"
import 'react18-json-view/src/style.css'
import { getDSerror } from "../data/getDataErroresDs"

function ModalModificar({cerrar}) {

    const queryClient = useQueryClient()
    const { data: sheets = {movil: '', rapido: '', provedores: ''}, isLoading: gettingSheet } = useQuery(["configs", "googleSheetPagos"], () => getDatos('/admin/getGoogleSheetPagos'), {
        onSuccess: (data) => {
            setValue('movil', data.movil)
            setValue('rapido', data.rapido)
        }
    })
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            movil: sheets.movil,
            rapido: sheets.rapido,
            provedores: sheets.provedores,
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setGoogleSheetPagos', parametros: datos})
    })

    const onSubmit = handleSubmit( datos => mutate(datos, {
        onSuccess: (data) => {
            if (data.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizado',
                    text: 'Se actualizó la información'
                }).then(() => {
                    cerrar();
                    queryClient.removeQueries({ queryKey: ["configs", "googleSheetPagos"] })
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.error ?? `No se pudo completar la operación`
                })
            }
        }
    }))

    return <div className="modal">
        { (isLoading || gettingSheet) && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                <h2>Guardar Url del Script</h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <form onSubmit={ onSubmit }>
                <div className="cont-inpt">
                    <label htmlFor="movil">Url del script Móvil:</label>
                    <input type="text" { ...register('movil') } />
                    <span>{ !!errors['movil'] && errors['movil'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="rapido">Url del script Pago Rápido:</label>
                    <input type="text" { ...register('rapido') } />
                    <span>{ !!errors['rapido'] && errors['rapido'].message }</span>
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    </div>
}

function MostrarDetallesFactura({ cerrar, idFactura }) {
    const { data, isLoading } = useQuery(["configs", "infoFactura", idFactura], () => getDatos('/admin/transacciones/factura', { id: idFactura }))

    return <div className="modal">
        { isLoading && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                <h2>Factura #{ idFactura }</h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <div className="detalles-factura-modal">
                <p><b>Id: </b>{ data?.factura.id }</p>
                <p><b>Id Cliente: </b>{ data?.factura.idcliente }</p>
                <p><b>Fecha de emisión: </b>{ data?.factura.emitido }</p>
                <p><b>Fecha de vencimiento: </b>{ data?.factura.vencimiento }</p>
                <p><b>Total: </b>${ data?.factura.total }</p>
                <p><b>Estado: </b>{ data?.factura.estado }</p>
                <p><b>Fecha Pago: </b>{ data?.factura.fechapago }</p>
                <p><b>Forma de pago: </b>{ data?.factura.formapago }</p>
                <Link to={ data?.factura.urlpdf  } target="_blank">Descargar</Link>
            </div>
        </div>
    </div>
}

function MostrarLogs({ cerrar }) {
    const { data, isLoading } = useQuery(["logTransacciones"], () => getDatos('/admin/transacciones/getLog'))
console.log(data)
    return <div className="modal">
        { isLoading && <Spinner/> }
        <div className="modal-modificar" style={{ width: '80%' }}>
            <div className="s-modal-header">
                <h2>Checkouts Recientes</h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <div className="detalles-factura-modal" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: 600 }}>
                <pre>{data?.log ?? 'No hay registros'}</pre>
            </div>
        </div>
    </div>
}

export default function Pagos() {

    const [ mostrarModal, setmostarmodal ] = useState(false)
    const [ mostrarModalFactura, setmostarmodalFactura ] = useState({
        mostrar: false,
        idFactura: 0
    })
    const [ mostrarLog, setMostrarLog ] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [search, setSearch] = useState('')
    const [visualizarJson, setVisualizarJson] = useState({})
    const [mostrarJson, setMostrarJson] = useState(false)
    const [origen, setOrigen] = useState(0) //0 todas, 1 movil, 2 web rapido, 3 distribuidores

    const { data: configs = { transacciones: [], total: 0, pagina: 1, totalItems: 0 }, isLoading, refetch } = useQuery(["configs", "pagos", page, search], () => getDatos('/admin/getPagos', { page: page, limit: limit, search: search, origen: origen }))

    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/delPago', parametros: datos})
    })

    const { mutate: getJson, isLoading: gettingJson } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/downloadPago', parametros: datos})
    })

    useEffect(() => {
        refetch();
    }, [search, page, limit, origen, refetch]);

    function sheet() {
        setmostarmodal(true)
    }

    function eliminar(id) {
        Swal.fire({
            icon: 'question',
            title: 'Eliminar?',
            text: 'Se eliminará este elemento',
            confirmButtonText: `Si, Eliminar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if  ( result.isConfirmed ) {
                mutate({id: id}, {
                    onSuccess: (data)=>{
                        if (data.ok) {
                            queryClient.removeQueries({ queryKey: ["configs", "pagos"] })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: data.error ?? `No se pudo completar la operación`
                            })
                        }
                    }
                })
            }
        })
    }

    const handleDownload = (id, trx) => {
        getJson({id: id}, {
            onSuccess: (json) => {
                const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
            
                const link = document.createElement('a');
                link.href = url;
                link.download = `info_pago_${trx}.json`;
                link.click();
            
                URL.revokeObjectURL(url);
            }
        })
    };
    const handleObserbar = (id) => {
        getJson({id: id}, {
            onSuccess: (json) => {
                setVisualizarJson(json)
                setMostrarJson(true)
            }
        })
    };

    function toPage(index) {
        setPage(index)
    }

    function buscar(value) {
        setPage(1)
        setSearch(value)
    }

    function origenInfo(value) {
        setPage(1)
        setOrigen(value)
    }

    function limitInfo(value) {
        setPage(1)
        setLimit(value)
    }

    const getIcon = origen => {
        switch (origen) {
            case 'Datafast Pago Rapido': return 'pago-rapido'
            case 'Datafast Aplicativo Móvil': return 'pago-movil'
            case 'Distribuidor': return 'pago-proveedor'
            default: return ''
        }
    }

    const extractNumber = (str) => {
        const match = str.match(/transaction_(\d+)/);
        return match ? match[1] : null;
    }

    return <div className="tablas">
        { mostrarModalFactura.mostrar && <MostrarDetallesFactura cerrar={ () => setmostarmodalFactura({mostrar: false, idFactura: 0}) } idFactura={ mostrarModalFactura.idFactura }/>}
        { mostrarLog && <MostrarLogs cerrar={ () => setMostrarLog(false) }/> }
        { mostrarJson && <div className="json-view-cont">
            <button className="btn-cerrar-modal" onClick={ () => setMostrarJson(false) }>Cerrar</button>
            <div className="cont-json">
                <JsonView enableClipboard={ false } theme={ 'vscode' } src={ visualizarJson } />
            </div>
        </div> }
        { (isLoading || isMutating || gettingJson) && <Spinner/> }
        { mostrarModal && <ModalModificar cerrar={ () => setmostarmodal(false) } /> }
        <div className="row">
            <div className="cont-tabla-imagenes">
                <div className="header-section">
                    <div className="cont-header-arrow">
                        <div className="cont-header-arrow-icon" onClick={ () => navigate(-1) } >
                            <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                        </div>
                        <h2>Transacciones</h2>
                    </div>
                    <div className="cont-header-search">
                        <label>Origen </label>
                        <select onChange={ (e) => origenInfo(e.target.value) }>
                            <option value="0">Todas</option>
                            <option value="1">Pago Móvil</option>
                            <option value="2">Pago Rápido</option>
                            <option value="3">Distribuidores</option>
                        </select>
                    </div>
                    <div className="cont-header-search">
                        <label>Mostrar </label>
                        <select onChange={ (e) => limitInfo(e.target.value) }>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div className="cont-header-search">
                        <input type="text" placeholder="buscar" onChange={ e => buscar(e.target.value) }/>
                    </div>
                    <button className="agregar" onClick={ ()=>setMostrarLog(true) } >Logs Checkouts</button>
                    <button className="agregar" onClick={ ()=>sheet({}) } >Google Sheets</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '10px' }}>#ID</th>
                            { origen === '3' && <th style={{ width: '10px' }}>Distribuidor</th> }
                            <th style={{ width: '120px' }}>Fecha</th>
                            <th style={{ width: '10px' }}>Total</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Email</th>
                            <th style={{ width: '80px' }}>Cedula</th>
                            <th style={{ width: '80px' }}>Transacción</th>
                            <th style={{ width: '25px' }}>Id Cliente</th>
                            <th>Contifico</th>
                            <th>Status</th>
                            <th style={{ width: '100px' }}>Json</th>
                            <th style={{ width: '10px' }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        configs?.transacciones.map((conf, i)=>{
                            let isErrorTx = conf.status !== '000.000.000 Transaccion aprobada'
                            const match = conf.status.match(/^\d{3}\.\d{3}\.\d{3}/);
                            let errorDetalle = 'No se encontró una descripcion mas detallada'
                            if (match) {
                                const transactionCode = match[0];
                                errorDetalle = getDSerror(transactionCode);
                            }
                            return <tr key={i}>
                                <td className={isErrorTx ? 'error' : ''}>
                                    <div className="cont-id-icon-table">
                                        { conf.id }
                                        <Icons req={ getIcon(conf.origen) } height="1.5em"/>
                                    </div>
                                </td>
                                { origen === '3' && <td className={isErrorTx ? 'error' : ''}>{ conf.id_distribuidor }</td> }
                                <td className={isErrorTx ? 'error' : ''}>{ conf.fecha_hora }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.total }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.primer_nombre }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.apellido }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.email }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.cedula }</td>
                                <td className={isErrorTx ? 'error' : ''}>
                                    <div className="cont-id-icon-table">
                                        { conf.trx }
                                        <button style={{backgroundColor: 'transparent'}} onClick={ () => setmostarmodalFactura({mostrar: true, idFactura: extractNumber(conf.trx)}) }  title="Obserbar">
                                            <Icons req={ 'i-circle-fill' } height={ '1.5em' } />
                                        </button>
                                    </div>
                                </td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.id_cliente }</td>
                                <td className={isErrorTx ? 'error' : ''}>{ conf.contifico }</td>
                                <td className={isErrorTx ? 'error' : ''} title={ errorDetalle }>{ conf.status }</td>
                                <td className={isErrorTx ? 'error' : ''}>
                                    <button onClick={()=>{ handleDownload(conf.id, conf.trx) }} title="Descargar Json">
                                        <Icons req={ 'download' } height={ '1.5em' } fill={'white'}/>
                                    </button>
                                    <button onClick={()=>{ handleObserbar(conf.id) }} title="Obserbar">
                                        <Icons req={ 'eye' } height={ '1.5em' } fill={'white'}/>
                                    </button>
                                </td>
                                <td className={isErrorTx ? 'error' : ''}>
                                    <button style={{ backgroundColor: 'red' }} onClick={()=>{ eliminar(conf.id) }} title="Eliminar">
                                        <Icons req={ 'delete' } height={ '1.5em' } fill={ 'white' } />
                                    </button>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <Pagination setPagina={ (index) => toPage(index) } totalPages={ configs?.total } totalItems={ configs?.totalItems } paginaActual={ page } itemsPagina={ configs?.transacciones.length } />
            </div>
        </div>
    </div>
}