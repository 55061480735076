import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const MapView = ({ initialLocation, polygonCoords, setCenter, setNewPolygon, setRadio, cordenadasPadre }) => {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDMjc8r7U-gBgu_80CI5Q0kW0uND-A68S8",
        libraries,
    });

    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);

    const R = 6371000;

    const metersToLatLng = (lat, lng, distance, bearing) => {
        const dLat = distance / R;
        const dLng = distance / (R * Math.cos(Math.PI * lat / 180));
        const newLat = lat + (dLat * 180) / Math.PI * Math.cos(bearing);
        const newLng = lng + (dLng * 180) / Math.PI * Math.sin(bearing);
        return { lat: newLat, lng: newLng };
    };

    const createTriangle = (center, sideLength) => {
        const angle = Math.PI / 3;
        const sideInMeters = sideLength;
        const vertex1 = { lat: center.lat, lng: center.lng };
        const vertex2 = metersToLatLng(center.lat, center.lng, sideInMeters, 0);
        const vertex3 = metersToLatLng(center.lat, center.lng, sideInMeters, angle);
        return [vertex1, vertex2, vertex3];
    };

    const getDistance = (lat1, lng1, lat2, lng2) => {
        const toRad = (value) => (value * Math.PI) / 180;
        const dLat = toRad(lat2 - lat1);
        const dLng = toRad(lng2 - lng1);
        const a = Math.sin(dLat / 2) ** 2 + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    function isValidCoordinate(point) {
        if (!point || typeof point !== 'object') {
            return false;
        }
    
        const { lat, lng } = point;
    
        if (
            typeof lat === 'number' &&
            typeof lng === 'number' &&
            !isNaN(lat) &&
            !isNaN(lng) &&
            lat >= -90 && lat <= 90 &&
            lng >= -180 && lng <= 180
        ) {
            return true;
        }
    
        return false;
    }

    useEffect(() => {
        let isEmpty = !isValidCoordinate(initialLocation)
        let isEmptyPolygon = polygonCoords.length === 0;
        async function initMap() {
            if (isLoaded && mapRef.current) {
                //@ts-ignore
                const { Map, Polygon } = await window.google.maps.importLibrary("maps");

                const getPolygonCenter = (path) => {
                    let bounds = new window.google.maps.LatLngBounds();
                    path.forEach((latLng) => {
                        bounds.extend(latLng);
                    });
                    return bounds.getCenter();
                };

                const polygonCenter = isEmpty ? cordenadasPadre : (!isEmptyPolygon ? getPolygonCenter(polygonCoords) : initialLocation);
                mapInstanceRef.current = new Map(mapRef.current, {
                    zoom: 15,
                    center: polygonCenter,
                    mapId: "COSTANET_MAP_ID",
                });

                let coveragePolygon;

                const updatePolygonVertices = () => {
                    const newPaths = coveragePolygon.getPath().getArray().map(latlng => ({
                        lat: latlng.lat(),
                        lng: latlng.lng(),
                    }));
                    const centerPolygon = getPolygonCenter(newPaths);
                    setCenter(centerPolygon);
                    setNewPolygon(newPaths);
                    let maxDist = 0;
                    newPaths.forEach(vertex => {
                        const dist = getDistance(centerPolygon.lat(), centerPolygon.lng(), vertex.lat, vertex.lng);
                        if (dist > maxDist) {
                            maxDist = dist;
                        }
                    });
                    setRadio(maxDist);
                };

                const handleMapClick = (event) => {
                    if (isEmpty && isEmptyPolygon) {
                        const clickLocation = { lat: event.latLng.lat(), lng: event.latLng.lng() };
                        const triangleCoords = createTriangle(clickLocation, 200);
                        coveragePolygon = new Polygon({
                            paths: triangleCoords,
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#FF0000",
                            fillOpacity: 0.35,
                            editable: true,
                        });
                        setNewPolygon(triangleCoords);
                        setCenter(clickLocation);

                        coveragePolygon.setMap(mapInstanceRef.current);
                        isEmpty = false;
                        isEmptyPolygon = false

                        window.google.maps.event.addListener(coveragePolygon, 'contextmenu', (event) => {
                            if (event.vertex !== undefined) {
                                coveragePolygon.getPath().removeAt(event.vertex);
                                updatePolygonVertices();
                            }
                        });

                        coveragePolygon.getPath().addListener('set_at', updatePolygonVertices);
                        coveragePolygon.getPath().addListener('insert_at', updatePolygonVertices);
                        coveragePolygon.getPath().addListener('remove_at', updatePolygonVertices);
                    }
                };

                mapInstanceRef.current.addListener('click', handleMapClick);

                if (!isEmpty) {
                    coveragePolygon = new Polygon({
                        paths: !isEmptyPolygon ? polygonCoords : createTriangle(initialLocation, 200),
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35,
                        editable: true,
                    });

                    coveragePolygon.setMap(mapInstanceRef.current);

                    window.google.maps.event.addListener(coveragePolygon, 'contextmenu', (event) => {
                        if (event.vertex !== undefined) {
                            coveragePolygon.getPath().removeAt(event.vertex);
                            updatePolygonVertices();
                        }
                    });

                    coveragePolygon.getPath().addListener('set_at', updatePolygonVertices);
                    coveragePolygon.getPath().addListener('insert_at', updatePolygonVertices);
                    coveragePolygon.getPath().addListener('remove_at', updatePolygonVertices);
                }
            }
        }

        initMap();
    }, [isLoaded, polygonCoords]);






    if (loadError) return <div>Error al cargar el mapa</div>;
    if (!isLoaded) return <div>Cargando...</div>;

    return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />

};

export default MapView;