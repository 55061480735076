import Spinner from "../components/Spinner"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from "../providers/dataServer"
import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import defaultFoto from '../assets/images/default-avatar.png'
import { urlServer } from "../providers/serverProvider"
import Icons from "../components/Icons"
import { useNavigate } from "react-router-dom"
import Pagination from "../components/Pagination"

function ModalModificar({elemento, cerrar}) {

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: elemento.id
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setPassword', parametros: datos})
    })

    const onSubmit = handleSubmit( datos => mutate(datos, {
        onSuccess: (data) => {
            if (data.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizado',
                    text: 'Se actualizó la información'
                }).then(() => {
                    cerrar();
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.error ?? `No se pudo completar la operación`
                })
            }
        }
    }))

    return <div className="modal">
        { isLoading && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                <h2>Actualizar la contraseña del usuario: { elemento.servicios_mikrowisp } </h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <form onSubmit={ onSubmit }>
                <div className="cont-inpt">
                    <label htmlFor="password">Nueva Contraseña:</label>
                    <input type="text" { ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Ingresa la nueva contraseña'
                                }, 
                                pattern: { 
                                    value: /^(?=.*[A-Z])(?=.*\d)(?=.{8,}).*$/, 
                                    message: 'La contraseña debe tener por lo menos 8 caracteres entre números y letras y una letra mayúscula'
                                } 
                            }) } />
                    <span>{ !!errors['password'] && errors['password'].message }</span>
                </div>
                <button type="submit">Actualizar</button>
            </form>
        </div>
    </div>
}

function ModalNotificar({elemento, cerrar}) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: elemento.id
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/notificarUsuario', parametros: datos})
    })

    const onSubmit = handleSubmit( datos => mutate(datos, {
        onSuccess: (data) => {
            if (data.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Notificado',
                    text: 'Se envió la notificación al usuario, si tiene activadas las notificaciones recibirá esta o cualquier notificación que le envíes'
                }).then(() => {
                    cerrar();
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.error ?? `No se pudo completar la operación`
                })
            }
        },
    }))

    return <div className="modal">
        { isLoading && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                <h2>Notificar a usuario: { elemento.servicios_mikrowisp } </h2>
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <form onSubmit={ onSubmit }>
                <div className="cont-inpt">
                    <label htmlFor="title">Titulo:</label>
                    <input type="text" { ...register('title', {
                                required: {
                                    value: true,
                                    message: 'Ingresa el título para la notificación'
                                }
                            }) } />
                    <span>{ !!errors['title'] && errors['title'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="body">Contenido:</label>
                    <textarea { ...register('body', {
                                required: {
                                    value: true,
                                    message: 'Ingresa el contenido de la notificación'
                                }
                            }) }>
                    </textarea>
                    <span>{ !!errors['body'] && errors['body'].message }</span>
                </div>
                <button type="submit">Notificar</button>
            </form>
        </div>
    </div>
}

export default function Usuarios() {

    const navigate = useNavigate()
    const [ elemento, setElemento ] = useState({})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [search, setSearch] = useState('')
    const [ mostrarModal, setmostarmodal ] = useState(false)
    const [ mostarmodalNotificar, setMostarmodalNotificar ] = useState(false)
    const queryClient = useQueryClient()

    const { data: configs = { usuarios: [], total: 0, pagina: 1, totalItems: 0 }, isLoading, refetch } = useQuery(["configs", "usuarios", page, search], () => getDatos('/admin/getUsuarios', { page: page, limit: limit, search: search }))
    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/delUsuario', parametros: datos})
    })

    function setPassword(elemento) {
        setElemento(elemento)
        setmostarmodal(true)
    }

    function eliminar(id) {
        Swal.fire({
            icon: 'question',
            title: 'Eliminar Este Usuario?',
            text: 'El usuario se eliminará permanentemente y ya no podrá ingresar a la aplicación, deberá volver a registrarse',
            confirmButtonText: `Si, Eliminar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if  ( result.isConfirmed ) {
                mutate({id: id}, {
                    onSuccess: (data)=>{
                        if (data.ok) {
                            queryClient.removeQueries({ queryKey: ["configs", "usuarios"] })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: data.error ?? `No se pudo completar la operación`
                            })
                        }
                    }
                })
            }
        })
    }
    useEffect(() => {
        refetch();
    }, [search, page, limit, refetch]);

    function notificar(elemento) {
        setElemento(elemento)
        setMostarmodalNotificar(true)
    }

    function toPage(index) {
        setPage(index)
    }

    function buscar(value) {
        setPage(1)
        setSearch(value)
    }

    function limitInfo(value) {
        setPage(1)
        setLimit(value)
    }

    return <div className="tablas">
        { (isLoading || isMutating) && <Spinner/> }
        { mostrarModal && <ModalModificar elemento={ elemento } cerrar={ () => setmostarmodal(false) } /> }
        { mostarmodalNotificar && <ModalNotificar elemento={ elemento } cerrar={ () => setMostarmodalNotificar(false) } /> }
        <div className="row">
            <div className="cont-tabla-imagenes">
                <div className="header-section">
                    <div className="cont-header-arrow">
                        <div className="cont-header-arrow-icon" onClick={ () => navigate(-1) } >
                            <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                        </div>
                        <h2>Usuarios Registrados en la App</h2>
                    </div>
                    <div className="cont-header-search">
                        <label>Mostrar </label>
                        <select onChange={ (e) => limitInfo(e.target.value) }>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div className="cont-header-search">
                        <input type="text" placeholder="buscar" onChange={ e => buscar(e.target.value) }/>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '10px' }}>#ID</th>
                            <th>Foto</th>
                            <th>{`ID(s) Mikrowisp`}</th>
                            <th>{`Nombre(s)`}</th>
                            <th>{`Apellidos(s)`}</th>
                            <th>Cédula</th>
                            <th>Correo</th>
                            <th>Fecha registro</th>
                            <th style={{ width: '10px' }}>Activado</th>
                            <th style={{ width: '150px' }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        configs?.usuarios.map((conf, i)=>{
                            return <tr key={i}>
                                <td>{ conf.id }</td>
                                <td className="cont-tabla-imagen">
                                    <img src={ conf.tieneFoto ? `${ urlServer }/${ conf.foto }` : defaultFoto } alt="foto"/>
                                </td>
                                <td>{ conf.servicios_mikrowisp }</td>
                                <td>{ conf.nombre }</td>
                                <td>{ conf.apellido }</td>
                                <td>{ conf.cedula }</td>
                                <td>{ conf.correo }</td>
                                <td>{ conf.fecha_alta }</td>
                                <td>{ conf.activacion ? 'SI' : 'NO' }</td>
                                <td>
                                    <button onClick={()=>{ setPassword(conf) }} title="Cambiar Contraseña">
                                        <Icons req={ 'password' } height={ '1.5em' } fill={'white'}/>
                                    </button>
                                    <button onClick={()=>{ notificar(conf) }} title="Notificar">
                                        <Icons req={ 'notify' } height={ '1.5em' } fill={'white'}/>
                                    </button>
                                    <button style={{backgroundColor: 'red'}} onClick={()=>{ eliminar(conf.id) }} title="Eliminar Usuario" >
                                        <Icons req={ 'delete' } height={ '1.5em' } fill={ 'white' } />
                                    </button>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <Pagination setPagina={ (index) => toPage(index) } totalPages={ configs?.total } totalItems={ configs?.totalItems } paginaActual={ page } itemsPagina={ configs?.usuarios.length } />
            </div>
        </div>
    </div>
}