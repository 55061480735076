import { useNavigate } from 'react-router';

export default function Dashboard() {

    const navigate = useNavigate()

    return <div className="dashboard">
        <h1>Administrador Costanet +</h1>
        <div className="contenido-dashboard">
            <div className="row-dashboard">
                <button onClick={ () => navigate('/imagenes') } >Imágenes APP</button>
                <button onClick={ () => navigate('/cobertura') } >Cobertura</button>
            </div>
            <div className="row-dashboard">
                <button onClick={ () => navigate('/pagos') } >Transacciones</button>
                <button onClick={ () => navigate('/usuarios') } >Usuarios App</button>
            </div>
            <div className="row-dashboard">
                <button onClick={ () => navigate('/distribuidores') } >Distribuidores</button>
            </div>
        </div>
    </div>
}