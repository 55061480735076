import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const MapCirculoCobertura = ({ initialLocation, initialRadio = 1, onLocationChange, onRadioChange, radio }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDMjc8r7U-gBgu_80CI5Q0kW0uND-A68S8",
        libraries,
    });

    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const markerRef = useRef(null);
    const circleRef = useRef(null);

    useEffect(() => {
        async function initMap() {
            if (isLoaded && mapRef.current) {
                const { Map, Circle } = await window.google.maps.importLibrary("maps");
                const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

                const initialPosition = initialLocation || { lat: -2.231655, lng: -80.0787448 };

                mapInstanceRef.current = new Map(mapRef.current, {
                    zoom: 15,
                    center: initialPosition,
                    mapId: "COSTANET_MAP_ID",
                });

                markerRef.current = new AdvancedMarkerElement({
                    map: mapInstanceRef.current,
                    position: initialPosition,
                    title: "Ubicación seleccionada",
                    gmpDraggable: true,
                });

                circleRef.current = new Circle({
                    center: initialPosition,
                    radius: radio * 1000,
                    map: mapInstanceRef.current,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                    editable: true,
                });

                const handleDragEnd = () => {
                    const newPosition = markerRef.current.position;
                    mapInstanceRef.current.setCenter(newPosition);
                    circleRef.current.setCenter(newPosition);
                    onLocationChange && onLocationChange(newPosition);
                };

                const handleRadiusChanged = () => {
                    const newRadius = circleRef.current.getRadius() / 1000;
                    onRadioChange && onRadioChange(newRadius);
                };

                markerRef.current.addListener('dragend', handleDragEnd);
                circleRef.current.addListener('radius_changed', handleRadiusChanged);
            }
        }

        initMap();
    }, [isLoaded]);

    useEffect(() => {
        if (circleRef.current) {
            circleRef.current.setRadius(radio * 1000);
        }
    }, [radio]);

    if (loadError) return <div>Error al cargar el mapa</div>;
    if (!isLoaded) return <div>Cargando...</div>;

    return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
};

export default MapCirculoCobertura;


