import { useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import { getDatos } from '../providers/dataServer'
import Swal from "sweetalert2";
import Spinner from '../components/Spinner'

export default function Login({ setlogeado }) {

    const { isLoading, refetch } = useQuery(["token"], () => getDatos('/admin/auth/login', { usuario: watch('usuario'), password: watch('password') }), {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
            if (data.ok) {
                localStorage.setItem('tempToken', data.token)
                setlogeado()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error de autenticación',
                    text: data.error ?? `El usuario o la contraseña son incorrectos`
                })
            }
        }
    })

    const { register, handleSubmit, formState: { errors }, watch } = useForm()

    const onSubmit = handleSubmit( _ => refetch())

    return (<div className="cont-login">
        { isLoading && <Spinner/> }
        <form onSubmit={ onSubmit }>
            <h2>Inicia sesión como</h2>
            <h1>Admin App Costanet+</h1>
            <div className="cont-items-form-login">
                <div className="cont-inpt">
                    <label htmlFor="usuario">Usuario:</label>
                    <input type="text" { ...register('usuario', {
                                required: {
                                    value: true,
                                    message: 'Ingresa un usuario válido'
                                },
                            }) } />
                    <span>{ !!errors['usuario'] && errors['usuario'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label>Contraseña:</label>
                    <input type="password" { ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Ingresa una contraseña válida'
                                },
                            }) } />
                    <span>{ !!errors['password'] && errors['password'].message }</span>
                </div>
                <button type='submit'>Ingresar</button>
            </div>
        </form>
    </div>)
}