export default function Pagination({ totalItems = null, totalPages, setPagina, paginaActual, itemsPagina }) {

    const mostrarAnterior = paginaActual > 1 
    const mostrarSiguiente = (paginaActual < totalPages) && ( totalPages > 1 )
    const botonesAMostrar = 9;
    const inicioPagina = Math.max(1, paginaActual - Math.floor(botonesAMostrar / 2));
    const finPagina = Math.min(totalPages + 1, inicioPagina + botonesAMostrar);
    //const finPagina = Math.min(totalPages, paginaActual + botonesAMostrar - 1);
    //const buttonsMaps = Array.from({ length: finPagina - paginaActual }, (_, index) => paginaActual + index);
    const buttonsMaps = Array.from({ length: finPagina - inicioPagina }, (_, index) => inicioPagina + index);

    return <div className="cont-buttons-footer-section">
        {totalPages > 1 ? <div className="cont-btns-next">
            { (botonesAMostrar < paginaActual) && <button className="btn-indice" onClick={ () => setPagina(1) }>Inicio</button> }
            { mostrarAnterior && <button className="btn-indice" onClick={ () => setPagina(paginaActual - 1) }>Anterior</button>}
            {
                buttonsMaps.map((item, index) => 
                <button key={ index } className={`btn-indice ${ paginaActual === item ? 'select' : '' }`} onClick={ () => setPagina(item) }>{item}</button>)
            }
            { mostrarSiguiente && <button className="btn-indice" onClick={ () => setPagina(paginaActual + 1) }>Siguiente</button>}
            { ((totalPages > botonesAMostrar) && mostrarSiguiente) && <button className="btn-indice" onClick={ () => setPagina(totalPages) }>Última</button> }
        </div> : <div></div>}
        <p>Mostrando { itemsPagina } de { totalItems } elementos</p>
    </div>
}