import { Outlet, useNavigate } from 'react-router';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { useState } from 'react';
import Icons from '../components/Icons';

export default function ContenApp({ logout }) {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const toggleLogOut = () => {
        localStorage.removeItem('tempToken')
        logout()
    }

    return <div className="content-app">
        <div className='status-bar'>
            <div className='status-bar-name'>
                <h2 className='st-title-name'>Bienvenido, Administrador de App Costanet+ <b>- Dpto: Admin</b></h2>
            </div>
            <div className='status-bar-actions'>
                <button onClick={ () => navigate('/') } className='st-button'>
                    <Icons req={'inicio'} fill='white' height='1.5em'/>
                </button>
                <button onClick={toggleDrawer} className='st-button'>
                    <Icons req={'user'} fill='white' height='1.5em'/>
                </button>
            </div>
        </div>
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction='right'
            className='drawer-element'
            size={300}
        >
            <div className='dr-header'>
                <button onClick={toggleDrawer} className='st-button'>
                    <Icons req={'arrow-left'} fill='white' height='1.5em'/>
                </button>
                <h4 className='dr-title-name'>Hola Administrador, <b>Admin</b></h4>
            </div>
            <ul className='drawer-content'>
                <li><div className='dr-div-info'><b>Nombre:</b> Admin</div></li>
                <li><div className='dr-div-info'><b>Rol Sistema:</b> Super Administrador</div></li>
                <li><div className='dr-div-info'><b>Departamento:</b> Administración</div></li>
                <li><div className='dr-div-info'><b>Cargo:</b> Administrador</div></li>
            </ul>
            <button className='btn-cerrar-sesion' onClick={ toggleLogOut }>Cerrar Sesión</button>
        </Drawer>
        <div className='cont-outlet'>
            <Outlet />
        </div>
    </div>
}