import { urlServer } from "./serverProvider";

export const getDatos = async (ruta, parametros = {}) => {
    let token = localStorage.getItem('tempToken') ?? ''
    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        if (data && typeof data.ok !== 'undefined' && !data.ok) {
            if (data.error === 'Unauthorized token' || data.error === 'Unauthorized') {
                localStorage.removeItem('tempToken')
                window.location.href = '/login'
            }
        }
        return data
    })
    .catch((error) => console.log(error))
}

export const mutarDatos = async ({ ruta, parametros }) => {
    let token = localStorage.getItem('tempToken') ?? ''
    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        if (data && typeof data.ok !== 'undefined' && !data.ok) {
            if (data.error === 'Unauthorized token' || data.error === 'Unauthorized') {
                localStorage.removeItem('tempToken')
                window.location.href = '/login'
            }
        }
        return data
    })
    .catch((error) => console.log(error))
}