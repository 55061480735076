import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { getDatos, mutarDatos } from "../providers/dataServer"
import Spinner from "../components/Spinner"
import Icons from "../components/Icons"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import Switch from "react-switch";

function ModalModificar({elemento, cerrar}) {

    const queryClient = useQueryClient()

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            ...elemento
        }
    })

    const { data: sectores = [] } = useQuery(["configs", "cobertura"], () => getDatos('/admin/getCobertura'))

    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setDistribuidor', parametros: datos})
    })

    const { mutate: setNewPassword, isSetingPassword } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setPasswordDistribuidor', parametros: datos})
    })

    const onSubmit = handleSubmit( datos => {
        if (!elemento.setPassword) {
            mutate(datos, {
                onSuccess: (data) => {
                    if (data.ok) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualizado',
                            text: 'Se actualizó la información'
                        }).then(() => {
                            cerrar();
                            queryClient.removeQueries({ queryKey: ["configs", "distribuidores"] })
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.error ?? `No se pudo completar la operación`
                        })
                    }
                }
            })
        } else {
            setNewPassword(datos, {
                onSuccess: (data) => {
                    if (data.ok) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualizado',
                            text: 'Se cambio la contraseña'
                        }).then(() => {
                            cerrar();
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.error ?? `No se pudo completar la operación`
                        })
                    }
                }
            })
        }
    })

    return <div className="modal">
        { (isLoading || isSetingPassword) && <Spinner/> }
        <div className="modal-modificar">
            <div className="s-modal-header">
                { !!elemento.setPassword ? <h2>Cambiar contraseña: { elemento.codigo }</h2> :
                <h2>{ !!elemento.id ? 'Modificar' : 'Agregar Distribuidor'} { elemento.codigo }</h2> }
                <button className="btn-cerrar-modal2" onClick={ cerrar }>Cerrar</button>
            </div>
            <form onSubmit={ onSubmit }>
                { !elemento.setPassword && <><div className="cont-inpt">
                    <label htmlFor="nombre">Nombre:</label>
                    <input type="text" { ...register('nombre', {
                                required: {
                                    value: true,
                                    message: 'El nombre es requerido'
                                }
                            }) } />
                    <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="apellido">Apellido:</label>
                    <input type="text" { ...register('apellido', {
                                required: {
                                    value: true,
                                    message: 'El apellido es requerido'
                                },
                            }) } />
                    <span>{ !!errors['apellido'] && errors['apellido'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="establecimiento">Establecimiento:</label>
                    <input type="text" { ...register('establecimiento', {
                                required: {
                                    value: true,
                                    message: 'Ingresa el nombre del establecimiento'
                                },
                            }) } />
                    <span>{ !!errors['establecimiento'] && errors['establecimiento'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="codigo">Código de distribuidor:</label>
                    <input type="text" { ...register('codigo', {
                                required: {
                                    value: true,
                                    message: 'El código es requerido'
                                },
                            }) } />
                    <span>{ !!errors['codigo'] && errors['codigo'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="cedula">Cédula:</label>
                    <input type="text" { ...register('cedula', {
                                required: {
                                    value: true,
                                    message: 'La cédula es requerida'
                                }
                            }) } />
                    <span>{ !!errors['cedula'] && errors['cedula'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="correo">Correo:</label>
                    <input type="email" { ...register('correo', {
                                required: {
                                    value: true,
                                    message: 'El correo es requerido'
                                }
                            }) } />
                    <span>{ !!errors['correo'] && errors['correo'].message }</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="sector">Sector:</label>
                    <select { ...register('sector', {
                        required: {
                            value: true,
                            message: 'El sector es requerido'
                        }
                    }) }>
                        <option value="">Asignar sector</option>
                        {
                            sectores?.map((sector) => <option key={ sector.id } value={ sector.id }>{ sector.nombre }</option>)
                        }
                    </select>
                    <span>{ !!errors['sector'] && errors['sector'].message }</span>
                </div></>}
                { (!elemento.id || !!elemento.setPassword) && <div className="cont-inpt">
                    <label htmlFor="password">Contraseña:</label>
                    <input type="text" { ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Escribe la contraseña para este distribuidor'
                                }
                            }) } />
                    <span>{ !!errors['password'] && errors['password'].message }</span>
                </div>}
                <button type="submit">Guardar</button>
            </form>
        </div>
    </div>
}

export default function Distribuidores() {

    const [ elemento, setElemento ] = useState({})
    const [ mostrarModal, setmostarmodal ] = useState(false)
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [search, setSearch] = useState('')

    const { data: configs = { distribuidores: [], total: 0, pagina: 1, totalItems: 0 }, isLoading, refetch } = useQuery(["configs", "distribuidores", page, search], () => getDatos('/admin/getDistribuidores', { page: page, limit: limit, search: search }))

    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/delDistribuidor', parametros: datos})
    })

    const { mutate: autorizar, isLoading: isAutorising } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/admin/setDistribuidorStatus', parametros: datos})
    })

    useEffect(() => {
        refetch();
    }, [search, page, limit, refetch]);

    function editar(elemento) {
        let el = elemento
        delete el.setPassword;
        setElemento(el)
        setmostarmodal(true)
    }

    function eliminar(id, codigo) {
        Swal.fire({
            icon: 'question',
            title: `Eliminar? ${ codigo }`,
            text: 'Se eliminará este distribuidor',
            confirmButtonText: `Si, Eliminar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if  ( result.isConfirmed ) {
                mutate({id: id}, {
                    onSuccess: (data)=>{
                        if (data.ok) {
                            queryClient.removeQueries({ queryKey: ["configs", "distribuidores"] })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: data.error ?? `No se pudo completar la operación`
                            })
                        }
                    }
                })
            }
        })
    }

    function setAutorizar(estado, id) {
        autorizar({id: id, status: estado ? 1 : 0}, {
            onSuccess: (data)=>{
                if (data.ok) {
                    queryClient.removeQueries({ queryKey: ["configs", "distribuidores"] })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.error ?? `No se pudo completar la operación`
                    })
                }
            }
        })
    }

    function setPassword(elemento) {
        let el = elemento
        el['setPassword'] = true
        setElemento(el)
        setmostarmodal(true)
    }

    function buscar(value) {
        setPage(1)
        setSearch(value)
    }

    function limitInfo(value) {
        setPage(1)
        setLimit(value)
    }

    return <div className="tablas">
    { (isLoading || isMutating || isAutorising) && <Spinner/> }
    { mostrarModal && <ModalModificar elemento={ elemento } cerrar={ () => setmostarmodal(false) } /> }
    <div className="row">
        <div className="cont-tabla-imagenes">
            <div className="header-section">
                <div className="cont-header-arrow">
                    <div className="cont-header-arrow-icon" onClick={ () => navigate(-1) } >
                        <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                    </div>
                    <h2>Lista de Distribuidores</h2>
                </div>
                <div className="cont-header-search">
                    <label>Mostrar </label>
                    <select onChange={ (e) => limitInfo(e.target.value) }>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="cont-header-search">
                    <input type="text" placeholder="buscar" onChange={ e => buscar(e.target.value) }/>
                </div>
                <button className="agregar" onClick={ ()=>editar({}) } >Agregar</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Codigo</th>
                        <th>Sector</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Establecimiento</th>
                        <th>Cédula</th>
                        <th>Correo</th>
                        <th>Fecha Ingreso</th>
                        <th># Trans.</th>
                        <th>Recaudado</th>
                        <th>Ganancias</th>
                        <th>Autorizado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                {
                    configs?.distribuidores.map((prov, i)=>{
                        return <tr key={i}>
                            <td>{ prov.id}</td>
                            <td>{ prov.codigo }</td>
                            <td>{ prov.nombreSector }</td>
                            <td>{ prov.nombre }</td>
                            <td>{ prov.apellido }</td>
                            <td>{ prov.establecimiento }</td>
                            <td>{ prov.cedula }</td>
                            <td>{ prov.correo }</td>
                            <td>{ prov.fecha_ingreso }</td>
                            <td>{ prov.numTransacciones }</td>
                            <td>${ prov.recaudado }</td>
                            <td>${ prov.ganancias }</td>
                            <td>
                                <Switch onChange={ (e) => setAutorizar(e, prov.id) } checked={prov.autorizado ? true : false} uncheckedIcon={false} checkedIcon={false}/>
                            </td>
                            <td>
                                <button onClick={()=> setPassword(prov) } title="Cambiar Contraseña">
                                    <Icons req={ 'password' } height={ '1.5em' } fill={'white'}/>
                                </button>
                                <button onClick={()=> editar(prov) } title="Modificar">
                                    <Icons req={ 'edit' } height={ '1.5em' } fill={'white'}/>
                                </button>
                                <button style={{backgroundColor: 'red'}} onClick={()=> eliminar(prov.id, prov.codigo) } title="Eliminar">
                                    <Icons req={ 'delete' } height={ '1.5em' } fill={ 'white' } />
                                </button>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    </div>
</div>
}