export default function Spinner() {
    return (
      <div className="loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', display: 'block'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="75" cy="50" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.985663082437276s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.985663082437276s"></animate>
          </circle><circle cx="71.65063509461098" cy="62.5" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.8960573476702509s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.8960573476702509s"></animate>
          </circle><circle cx="62.5" cy="71.65063509461096" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.8064516129032258s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.8064516129032258s"></animate>
          </circle><circle cx="50" cy="75" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.7168458781362007s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.7168458781362007s"></animate>
          </circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.6272401433691757s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.6272401433691757s"></animate>
          </circle><circle cx="28.34936490538903" cy="62.5" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.5376344086021505s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.5376344086021505s"></animate>
          </circle><circle cx="25" cy="50" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.44802867383512546s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.44802867383512546s"></animate>
          </circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.35842293906810035s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.35842293906810035s"></animate>
          </circle><circle cx="37.499999999999986" cy="28.34936490538904" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.26881720430107525s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.26881720430107525s"></animate>
          </circle><circle cx="49.99999999999999" cy="25" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.17921146953405018s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.17921146953405018s"></animate>
          </circle><circle cx="62.5" cy="28.349364905389034" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.08960573476702509s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.08960573476702509s"></animate>
          </circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#ff7c7c" r="6">
            <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="0s"></animate>
            <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="0s"></animate>
          </circle>
        </svg>
      </div>
    )
  }