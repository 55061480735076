import data from './errores.json';

const getDSerror = (error) => {
    for (let i = 0; i < data.length; i++) {
        console.log(data[i].codigo)
        if (data[i].codigo === error) {
            return data[i].detalle
        }
    }
    return 'No se encontró una descripcion mas detallada'
}

export { getDSerror }