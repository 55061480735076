import Spinner from "../components/Spinner"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from "../providers/dataServer"
import { useCallback, useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import Icons from "../components/Icons"
import { useNavigate } from "react-router-dom"
import MapCirculoCobertura from "../components/MapCirculoCobertura"
import MapaSectores from "../components/MapaSectores"

function AddEditSectorPadre({ elemento, cerrar }) {
    const queryClient = useQueryClient()
    const [mostrarClick, setMostrarClick] = useState(false);
    const [initialLocation, setInitialLocation] = useState(null);
    const [initialRadio, setInitialRadio] = useState(1);
    const [radio, setRadio] = useState(1)

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            ...elemento,
            idPadre: 0
        }
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/admin/setCobertura', parametros: datos })
    })

    useEffect(() => {
        if (!!elemento.id) {
            setInitialLocation({ lat: parseFloat(elemento.latitud), lng: parseFloat(elemento.longitud) })
            setInitialRadio(elemento.radio_cobertura)
            setRadio(elemento.radio_cobertura)
            setValue('radio_cobertura', elemento.radio_cobertura)
        } else {
            setValue('radio_cobertura', 1)
            setMostrarClick(true)
        }
    }, [elemento, setValue])

    function onLocationChange(cordenadas) {
        setValue('latitud', cordenadas.lat)
        setValue('longitud', cordenadas.lng)
        setMostrarClick(false)
    }

    const handleRadioChange = (newRadio) => {
        setRadio(newRadio);
        setValue('radio_cobertura', newRadio)
    }

    const onSubmit = handleSubmit(datos => {
        mutate(datos, {
            onSuccess: (data) => {
                if (data.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Se actualizó la información'
                    }).then(() => {
                        cerrar();
                        queryClient.removeQueries({ queryKey: ["configs", "cobertura"] })
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.error ?? `No se pudo completar la operación`
                    })
                }
            }
        })
    })

    return <div className="modal">
        {isLoading && <Spinner />}
        <div className="modal-modificar" style={{ width: 600 }}>
            <div className="s-modal-header">
                <h2>{!!elemento.id ? 'Modificar' : 'Agregar'} {elemento.nombre}</h2>
                <button className="btn-cerrar-modal2" onClick={cerrar}>Cerrar</button>
            </div>
            <form onSubmit={onSubmit}>
                <div className="cont-inpt">
                    <label htmlFor="nombre">Nombre Ciudad:</label>
                    <input type="text" {...register('nombre', {
                        required: {
                            value: true,
                            message: 'El nombre es requerido'
                        }
                    })} />
                    <span>{!!errors['nombre'] && errors['nombre'].message}</span>
                </div>
                <div className="cont-inpt">
                    <label htmlFor="tecnico">Técnico Asignado:</label>
                    <input type="tecnico" {...register('tecnico', {
                        required: {
                            value: true,
                            message: 'El tecnico es requerido'
                        }
                    })} />
                    <span>{!!errors['tecnico'] && errors['tecnico'].message}</span>
                </div>
                <p className="titulo-sectores-mapa">Cobertura general relativa</p>
                <MapCirculoCobertura initialLocation={initialLocation} initialRadio={initialRadio} onLocationChange={onLocationChange} onRadioChange={handleRadioChange} radio={radio} />
                {mostrarClick && <p>Arrastra el marcador a la ubicación del nuevo sector</p>}
                <div className="cont-inpt">
                    <label htmlFor="radio_cobertura">Radio: {radio} Km</label>
                    <input hidden={mostrarClick} type="range" step={0.1} min={0.1} max={20} {...register('radio_cobertura', {
                        onChange: (event) => {
                            setRadio(Number(event.target.value))
                        },
                    })} />
                    <span>{!!errors['radio_cobertura'] && errors['radio_cobertura'].message}</span>
                </div>
                {!mostrarClick && <button type="submit">Guardar</button>}
            </form>
        </div>
    </div>
}

export default function Cobertura() {

    const [elemento, setElemento] = useState({})
    const [sectores, setSectores] = useState([])
    const [ centroMapa, setCentroMapa ] = useState({ lat: -2.275190, lng: -80.424276 })
    const [resaltar, setResaltar] = useState(0)
    const [mostrarModal, setmostarmodal] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { data: configs = [], isLoading } = useQuery(["configs", "cobertura"], () => getDatos('/admin/getCobertura'), {
        refetchOnMount: true,
        onSuccess: data => {
            if (data) {
                const sectores = transformData(data)
                setSectores(sectores)
            }
        }
    })
    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/admin/delCobertura', parametros: datos })
    })

    function editar(elemento) {
        setElemento(elemento)
        setmostarmodal(true)
    }

    function eliminar(id) {
        Swal.fire({
            icon: 'question',
            title: 'Eliminar Cobertura?',
            text: 'Se eliminará este sector con todos los subsectores',
            confirmButtonText: `Si, Eliminar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if (result.isConfirmed) {
                mutate({ id: id }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.removeQueries({ queryKey: ["configs", "cobertura"] })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: data.error ?? `No se pudo completar la operación`
                            })
                        }
                    }
                })
            }
        })
    }

    function transformData(data) {
        const sectores = [];

        data.forEach(ciudad => {
            ciudad.poligonos.forEach(poligono => {
                const sector = poligono.map(vertice => ({
                    lat: vertice.lat,
                    lng: vertice.lng,
                    color: ciudad.color,
                    nombre: ciudad.nombre,
                    idZona: ciudad.id,
                    centro: { lat: parseFloat(ciudad.latitud), lng: parseFloat(ciudad.longitud) }
                }));
                sectores.push(sector);
            });
        });

        return sectores;
    }

    const resaltarFila = useCallback((id, coordenadasCentro) => {
        try {
            setCentroMapa({ lat: coordenadasCentro.lat(), lng: coordenadasCentro.lng() })
        } catch (error) {
            setCentroMapa({ lat: coordenadasCentro.lat, lng: coordenadasCentro.lng })
        }
        setResaltar(id)
    }, []);

    const handleSectorClick = (cordenadas) => {
        setCentroMapa(cordenadas)
    };

    return <div className="tablas">
        {(isLoading || isMutating) && <Spinner />}
        {mostrarModal && <AddEditSectorPadre ModalModificar elemento={elemento} cerrar={() => setmostarmodal(false)} />}
        <div className="row">
            <div className="cont-tabla-imagenes">
                <div className="header-section">
                    <div className="cont-header-arrow">
                        <div className="cont-header-arrow-icon" onClick={() => navigate(-1)} >
                            <Icons req={ 'arrow-left' } height={ '1.5em' } fill="white" />
                        </div>
                        <h2>Ciudades Cobertura</h2>
                    </div>
                    <button className="agregar" onClick={() => editar({})} >Agregar</button>
                </div>
                <div className="cont-mapa-hijos">
                    <MapaSectores sectores={sectores} cordenadasPadre={centroMapa} setResaltado={(id, cor) => resaltarFila(id, cor)} onCloseInfoWindow={() => setResaltar(0)}/>
                </div>
                <div style={{ marginTop: 0, height: "calc(100vh - 530px)", overflowY: "auto", position: 'relative' }}>
                <table style={{ marginTop: 0 , marginBottom: 0}}>
                    <thead style={{ top: 0 }}>
                        <tr>
                            <th style={{ width: 10 }}>#ID</th>
                            <th>Ciudad</th>
                            <th style={{ width: 10 }}>Subsectores</th>
                            <th>Latitud</th>
                            <th>Longitud</th>
                            <th>Técnico</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            configs.map((conf, i) => {
                                return <tr key={i} style={ resaltar === conf.id ? { backgroundColor: '#00ff9e' } : {}}>
                                    <td style={{ backgroundColor: conf.sectores > 0 ? conf.color : 'transparent', cursor: 'pointer' }} onClick={() => handleSectorClick({lat: conf.latitud, lng: conf.longitud})}>{conf.id}</td>
                                    <td>{conf.nombre}</td>
                                    <td>{conf.sectores}</td>
                                    <td>{conf.latitud}</td>
                                    <td>{conf.longitud}</td>
                                    <td>{conf.tecnico}</td>
                                    <td>
                                        <button onClick={() => editar(conf)} title="Editar">
                                            <Icons req={'edit'} height={'1.5em'} fill={'white'}/>
                                        </button>
                                        <button onClick={() => navigate(`/cobertura/${conf.id}/${conf.nombre}/${JSON.stringify({ lat: conf.latitud, lng: conf.longitud })}`)} title="Administrar Sectores">
                                            <Icons req={'list'} height={'1.5em'} fill={'white'}/>
                                        </button>
                                        <button style={{ backgroundColor: 'red' }} onClick={() => { eliminar(conf.id) }} title="Eliminar">
                                            <Icons req={'delete'} height={'1.5em'} fill={'white'} />
                                        </button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>
}