import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const MapaSectores = forwardRef(({ sectores, cordenadasPadre, setResaltado, onCloseInfoWindow }, ref) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDMjc8r7U-gBgu_80CI5Q0kW0uND-A68S8",
        libraries,
    });

    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const polygonsRef = useRef([]);
    const infoWindowRef = useRef(null);

    const clearPolygons = () => {
        polygonsRef.current.forEach(polygon => polygon.setMap(null));
        polygonsRef.current = [];
    };

    useImperativeHandle(ref, () => ({
        triggerPolygonClick(idZona) {
            const polygon = polygonsRef.current.find(polygon => polygon.idZona === idZona);
            if (polygon) {
                window.google.maps.event.trigger(polygon, 'click');
            }
        }
    }));

    useEffect(() => {
        if (isLoaded && mapRef.current) {
            const { Map, Polygon, InfoWindow } = window.google.maps;

            if (!mapInstanceRef.current) {
                const mapCenter = { lat: parseFloat(cordenadasPadre.lat), lng: parseFloat(cordenadasPadre.lng) };
                mapInstanceRef.current = new Map(mapRef.current, {
                    zoom: 12,
                    center: mapCenter,
                    mapId: "COSTANET_MAP_ID",
                });
            } else {
                mapInstanceRef.current.setCenter({ lat: parseFloat(cordenadasPadre.lat), lng: parseFloat(cordenadasPadre.lng) });
            }

            clearPolygons();

            sectores.forEach((sector) => {
                const paths = sector.map(({ lat, lng }) => ({ lat, lng }));
                const color = sector[0].color || '#FF0000';
                const nombre = sector[0].nombre || 'Zona sin nombre';
                const idZona = sector[0].idZona || 0;
                const centro = sector[0].centro || cordenadasPadre;

                const polygon = new Polygon({
                    paths,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.35,
                });

                polygon.idZona = idZona;

                polygon.setMap(mapInstanceRef.current);
                polygonsRef.current.push(polygon);

                polygon.addListener('click', (event) => {
                    if (infoWindowRef.current) {
                        infoWindowRef.current.close();
                    }

                    const centroResaltado = event?.latLng || centro
                    setResaltado(idZona, centroResaltado);

                    const newInfoWindow = new InfoWindow({
                        content: `<div>${nombre}</div>`,
                        position: centroResaltado,
                    });

                    newInfoWindow.addListener('closeclick', () => {
                        onCloseInfoWindow && onCloseInfoWindow(idZona);
                    });

                    newInfoWindow.open(mapInstanceRef.current);
                    infoWindowRef.current = newInfoWindow;
                });
            });
        }
    }, [isLoaded, sectores, cordenadasPadre, setResaltado, onCloseInfoWindow]);

    useEffect(() => {
        return () => {
            clearPolygons();
            if (infoWindowRef.current) {
                infoWindowRef.current.close();
            }
        };
    }, []);

    if (loadError) return <div>Error al cargar el mapa</div>;
    if (!isLoaded) return <div>Cargando...</div>;

    return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
});

export default MapaSectores;
